import { Badge, Col, Grid, Layout } from 'antd';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { setCookie } from 'nookies';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { GetBrandSettingFromRecoil } from '../../recoil/brandsetting';
import { notificationCountState } from '../../recoil/notificationCount';
import { GetVideoIdFromRecoil } from '../../recoil/playlist';
import { privacyTypeState } from '../../recoil/privacyType';
import { getPrivacyType } from '../../utils/functions/confidentiality';
import { getContent, handleSideBar } from '../../utils/functions/contentParams';
import { loadMatomo } from '../../utils/functions/matomo';
import { getCurrentItemFromPlaylist } from '../../utils/functions/player';
import useWindowSize from '../../utils/hooks/useWindowSize';
import { useCheckSession } from '../../utils/store/confidentiality';
import Header from '../Header';
import { ShowSidebarIcon } from '../Icons';
import Poll from '../Poll';
import RGPDModal from '../RGPDModal';
import TabsPlayer from '../Tabs';
import Discussion from '../Discussion';
import Footer from '../../public/SUAB_footer.jpg';

const VideoPlayer = dynamic(() => import('../Player/index.js'), {
  ssr: false
});
const Description = dynamic(() => import('../Description'), { ssr: false });

const { Content, Sider } = Layout;
const { useBreakpoint } = Grid;

const Main = function ({ live, media, playlist, isEmbed, webTvId }) {
  const { content, contentType } = getContent(media, live, playlist);
  const { shouldShowPoll, shouldShowSideBar, sidebarHasContent } =
    handleSideBar(content, contentType);
  const [isMuted, setIsMuted] = useState(false);
  const [disableAnalytic, setDisableAnalytic] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const [mode, setMode] = useState('normal');
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [height, setHeight] = useState(0);
  const [title, setTitle] = useState();
  const [videoLanguage, setVideoLanguage] = useState();
  const [layoutStyle, setLayoutStyle] = useState();
  const [pollId, setPollId] = useState();
  const [multiSession, setMultiSession] = useState(false);
  const screens = useBreakpoint();
  const router = useRouter();

  const currentPlaylistContentId = GetVideoIdFromRecoil();
  const { pageTheme, videoTitle, accentColor } = GetBrandSettingFromRecoil();
  const checkSession = useCheckSession();
  const [privacy, setPrivacy] = useRecoilState(privacyTypeState);
  const count = useRecoilValue(notificationCountState);

  const { width: windowWidth } = useWindowSize();

  useEffect(() => {
    const privacity = getPrivacyType({ content });
    setPrivacy(privacity);
  }, [content]);

  // useInterval(async () => {
  //   if (privacy !== 'inscription' && privacy !== 'private') return;
  //   setMultiSession((await checkSession()).data);
  // }, 1000 * 60 * 5);

  useEffect(() => {
    if (multiSession) {
      setCookie(null, 'contentToken', '', {});
      router.reload();
    }
  }, [multiSession]);

  useEffect(() => {
    if (shouldShowSideBar && sidebarHasContent) {
      setCollapsed(false);
    } else {
      setCollapsed(true);
    }
  }, [shouldShowSideBar, webTvId, screens]);

  useEffect(() => {
    const vjsModeControl =
      document.getElementsByClassName('vjs-mode-control')[0];
    if (!collapsed) {
      setMode('normal');
      vjsModeControl && vjsModeControl.classList.remove('vjs-mode');
    }
  }, [collapsed]);

  useEffect(() => {
    if (typeof window === 'undefined' || disableAnalytic) return;
    loadMatomo();
  }, [disableAnalytic]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      window.dispatchEvent(new Event('scroll'));
    }, 500);
    return () => clearTimeout(timeout);
  }, [collapsed]);

  useEffect(() => {
    if (webTvId) return;
    if (live?.status === 'Ended' && live?.hasReplay && live?.replay?.id) {
      setShouldRedirect(true);
      // TODO prefetech the next router page with <Link>
      const timeout = setTimeout(() => {
        if (isEmbed) {
          window.location = `/embed/media/${live.replay.id}`;
        } else {
          window.location = `/media/${live.replay.id}`;
        }
      }, 5000);
      return () => clearTimeout(timeout);
    }
  }, [live, isEmbed, router, webTvId]);

  function setHeaderHeight() {
    const breadcrumb = document.getElementById('breadcrumb');
    if (breadcrumb) {
      const breadCrumbBounding = breadcrumb.getBoundingClientRect();
      const breadcrumbVisible =
        breadCrumbBounding.height + breadCrumbBounding.top;
      setHeight(breadcrumbVisible);
    } else {
      const header = document.getElementById('header');
      if (header) {
        const headerBounding = header.getBoundingClientRect();
        const headerHeight = headerBounding.height + headerBounding.top;
        setHeight(headerHeight < 0 ? 0 : headerHeight);
      }
    }
  }

  useEffect(() => {
    if (!collapsed) {
      setMode('normal');
    }
  }, [collapsed]);

  useEffect(() => {
    function watchScroll() {
      window.addEventListener('scroll', setHeaderHeight);
    }
    watchScroll();
    return () => {
      window.removeEventListener('scroll', setHeaderHeight);
    };
  });

  // set the video title because there is some logic to do with playlist
  useEffect(() => {
    if (!content || !contentType) return;
    if (contentType !== 'playlist') {
      setTitle(videoTitle);
      setPollId(content?.live?.poll?.id || content?.poll?.id);
    } else {
      const item = getCurrentItemFromPlaylist(
        content,
        currentPlaylistContentId
      );
      setTitle(item?.pageSetting?.videoTitle);
      setVideoLanguage(item?.language);
      setPollId(item?.poll?.id);
    }
  }, [content, contentType, videoTitle, currentPlaylistContentId]);

  useEffect(() => {
    if (router.query.disableAnalytic) {
      setDisableAnalytic(true);
    } else {
      content?.disableAnalytic && setDisableAnalytic(content?.disableAnalytic);
    }
  }, [content]);

  useEffect(() => {
    const styleTmp = {
      overflow: screens.sm ? 'inherit' : 'hidden',
      minHeight: screens.sm ? '100vh' : 'inherit'
    };
    // if (!screens.sm) {
    //   if (CSS.supports('max-height', '100svh')) {
    //     styleTmp.height = '100svh';
    //   } else {
    //     styleTmp.height = '100vh';
    //   }
    // }
    setLayoutStyle(styleTmp);
  }, [screens]);

  // MEDIAS & PLAYLISTS & LIVE
  return (
    <Layout
      style={{
        maxWidth: '1100px',
        margin: '0 auto',
        height: screens.sm ? '100%' : 'inherit',
        ...layoutStyle
      }}
      data-cy="layout"
    >
      {/* {!isEmbed && !webTvId && <Header />} */}
      <img src="/SUAB-header-player.png" alt="hero" style={{ width: '100%' }} />

      <Layout
        style={{
          width: '100%',
          position: 'relative',
          margin: 0,
          height: screens.sm ? '100%' : 'inherit'
        }}
      >
        <Content>
          <div
            style={{
              position: 'relative',
              overflow: 'hidden',
              maxWidth: '100%',
              height: screens.sm ? '100%' : 'inherit'
            }}
          >
            <div
              style={{
                position: 'relative',
                maxWidth: '100vw',
                height: screens.sm ? '100%' : 'inherit'
              }}
            >
              <VideoPlayer
                media={media}
                live={live}
                playlist={playlist}
                isEmbed={isEmbed}
                collapsed={mode === 'large' ? true : collapsed}
                mode={mode}
                sidebarHasContent={sidebarHasContent}
                setMode={setMode}
                shouldRedirect={shouldRedirect}
                disableAnalytic={disableAnalytic}
                isMuted={isMuted}
                setIsMuted={setIsMuted}
                fromWebTV={!!webTvId}
              />
            </div>
          </div>

          {!screens.sm && sidebarHasContent && (
            <Col span={24} style={{ position: 'relative', overflow: 'hidden' }}>
              <TabsPlayer
                isEmbed={isEmbed}
                media={media}
                live={live}
                playlist={playlist}
                webTvId={webTvId}
                pollId={pollId}
                title={videoTitle}
                isMuted={isMuted}
              />
            </Col>
          )}
        </Content>
        {sidebarHasContent && screens.sm && (
          <Sider
            trigger={null}
            // collapsible
            collapsed={mode === 'large' ? true : collapsed}
            collapsedWidth={0}
            width={Math.ceil(windowWidth / 3)}
            theme={pageTheme}
            style={{
              maxHeight: screens.sm
                ? `calc(${height > 0 ? `100vh - ${height}px` : '100vh'})`
                : 'initial',
              position: 'sticky',
              top: 0,
              zIndex: 111,
              boxShadow: webTvId
                ? ''
                : 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px'
            }}
          >
            <TabsPlayer
              isEmbed={isEmbed}
              media={media}
              live={live}
              playlist={playlist}
              webTvId={webTvId}
              title={title}
              isMuted={isMuted}
              collapsed={mode === 'large' ? true : collapsed}
              setCollapsed={setCollapsed}
            />
          </Sider>
        )}
      </Layout>
      <footer
        style={{
          position: 'relative',
          width: '100%',
          height: '150px',
          margin: 0
        }}
      >
        <img
          src={Footer.src}
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
      </footer>
      <RGPDModal />
      <style jsx>
        {`
          .icon-theater {
            display: inline-block;
            position: relative;
          }
          .icon-theater:before {
            font-family Nuevo;
            font-size: 26px;
            font-weight: 400;
            content: "\e907";
          }
          .icon-theater.collapsed:before {
            content: "\e906";
          }
          .sidebar-aside {
            display: flex;
            align-items: start;
            justify-content: center;
            position: absolute;
            top: 0;
            right: 0;
            width: 65px;
            z-index: 100;
            height: 100%;
            cursor: pointer;
            opacity: ${collapsed || mode === 'large' ? 1 : 0};
            pointer-events: ${collapsed || mode === 'large' ? 'all' : 'none'};
            transition: all .2s ease-in-out;
          }
          .sidebar-badge {
            padding: 16px 4px;
          }
          .sidebar-icon {
            opacity: .8;
            transition: all .2s ease-in-out;
          }
          .sidebar-background {
            position: absolute;
            right: 0;
            width: 60px;
            background: linear-gradient(90deg, transparent,rgba(255,255,255,.2));
            height: 100%;
            transform: translateX(60px);
            transition: all .2s ease-in-out;
          }

          .sidebar-aside:hover .sidebar-background {
            transform: translateX(0px);
          }
          .sidebar-aside:hover .sidebar-icon {
            opacity: 1;
          }
      `}
      </style>
    </Layout>
  );
};

export default Main;
