import {
  BookOutlined,
  CommentOutlined,
  FileOutlined,
  MenuUnfoldOutlined,
  TranslationOutlined,
  UnorderedListOutlined
} from '@ant-design/icons';
import { Badge, Button, Tabs } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import React, { memo, useEffect, useState } from 'react';
import { FaPoll } from 'react-icons/fa';
import { useRecoilState, useRecoilValue } from 'recoil';
import { GetBrandSettingFromRecoil } from '../../recoil/brandsetting';
import { discussionState } from '../../recoil/discussion';
import { notificationCountState } from '../../recoil/notificationCount';
import { notificationShouldUpdateState } from '../../recoil/notificationShouldUpdate';
import { GetVideoIdFromRecoil } from '../../recoil/playlist';
import { activeQuestionState } from '../../recoil/poll';
import {
  getContent,
  getContentPlaying,
  handleSideBar
} from '../../utils/functions/contentParams';
import useTabHeight from '../../utils/hooks/useTabHeight';
import { HideSidebarIcon } from '../Icons';

const Discussion = dynamic(() => import('../Discussion'));
const Chapters = dynamic(() => import('../Chapters'));
const Transcript = dynamic(() => import('../Transcript'));
const Files = dynamic(() => import('../Files'));
const Playlist = dynamic(() => import('../Playlist'));
const Poll = dynamic(() => import('../Poll'));

const { TabPane } = Tabs;

const TabsPlayer = function ({
  isEmbed,
  media,
  live,
  webTvId,
  pollId,
  playlist,
  title,
  isMuted,
  collapsed,
  setCollapsed
}) {
  const [items, setItems] = useState([]);
  const { t } = useTranslation('common');
  const screens = useBreakpoint();
  const { content, contentType, isPlaylist } = getContent(
    media,
    live,
    playlist
  );
  const { contentPlaying, contentTypePlaying, contentIdPlaying } =
    getContentPlaying(media, live, playlist);

  const { tabHeight } = useTabHeight();

  const { accentColor, pageTheme } = GetBrandSettingFromRecoil();
  const currentPlaylistContentId = GetVideoIdFromRecoil();

  const [shouldUpdate, setShouldUpdate] = useRecoilState(
    notificationShouldUpdateState
  );

  const count = useRecoilValue(notificationCountState);
  const activeQuestion = useRecoilValue(activeQuestionState);
  const [isQAndA, setIsQAndA] = useRecoilState(discussionState);

  const [activeKey, setActiveKey] = useState();

  const onUpdateTabs = (newKey) => {
    newKey !== 'discussion' && setShouldUpdate(true);
    setActiveKey(newKey);
    setTimeout(() => {
      window.dispatchEvent(new Event('resizeUseTabHeight'));
    }, 500);
  };

  // fix the is embed missing (in discussion scope, not live/media)
  // const shouldShowDiscussion = (media || live)?.shouldShowDiscussion && (media || live)?.isEmbed
  const {
    files,
    shouldShowTranscript,
    transcripts,
    discussion,
    shouldShowChapter,
    shouldShowPoll,
    shouldShowFile,
    shouldShowDiscussion
  } = handleSideBar(content, contentType, currentPlaylistContentId);

  const getDefaultActiveKey = () => {
    if (contentType === 'playlist') {
      return 'playlist';
    } else if (shouldShowDiscussion && discussion?.id) {
      return 'discussion';
    } else if (shouldShowChapter) {
      return 'chapters';
    } else if (shouldShowTranscript && transcripts?.length) {
      return 'transcript';
    } else if (shouldShowFile && files?.length) {
      return 'files';
    } else {
      return '';
    }
  };

  useEffect(() => {
    setActiveKey(getDefaultActiveKey());
  }, []);

  useEffect(() => {
    if (screens.sm) return;
    if (activeQuestion) {
      setActiveKey('poll');
    } else {
      setActiveKey(getDefaultActiveKey());
    }
  }, [activeQuestion, screens]);

  useEffect(() => {
    if (!discussion) return;
    setIsQAndA(discussion?.preset === 'QANDA');
  }, [discussion?.preset]);

  useEffect(() => {
    const tmpItems = [];
    if (isPlaylist) {
      tmpItems.push({
        key: 'playlist',
        label: (
          <span data-cy="tab-playlist">
            {screens.sm ? (
              t('playlist')
            ) : (
              <>
                <UnorderedListOutlined
                  style={{
                    fontSize: 16,
                    color:
                      pageTheme === ' dark' ? 'rgba(255, 255, 255, .85' : 'auto'
                  }}
                />
                {activeKey === 'playlist' && t('playlist')}
              </>
            )}
          </span>
        ),
        children: (
          <div className="tab__container">
            <Playlist playlist={content} isEmbed={isEmbed} />
          </div>
        )
      });
    }

    if (shouldShowPoll && !screens.sm && activeQuestion) {
      tmpItems.push({
        key: 'poll',
        label: (
          <span data-cy="tab-poll">
            <FaPoll style={{ fontSize: 16, marginRight: 16 }} />
            <>{activeKey === 'poll' && t('poll')}</>
          </span>
        ),
        children: (
          <div className="tab__container">
            <Poll
              pollId={pollId}
              color={accentColor}
              theme={pageTheme}
              isEmbed={true}
              isMuted={isMuted}
              contentType={contentType}
            />
          </div>
        )
      });
    }

    if (shouldShowDiscussion) {
      tmpItems.push({
        key: 'discussion',
        label: (
          <Badge count={count} offset={[12, 0]} data-cy="discussion-count">
            <span data-cy="tab-discussion">
              {screens.sm ? (
                'Questions & commentaires'
              ) : (
                <>
                  <CommentOutlined
                    style={{
                      fontSize: 16,
                      color:
                        pageTheme === ' dark'
                          ? 'rgba(255, 255, 255, .85'
                          : 'auto'
                    }}
                  />
                  {activeKey === 'discussion' && 'Questions & commentaires'}
                </>
              )}
            </span>
          </Badge>
        ),
        children: (
          <Discussion
            discussionId={discussion?.id}
            theme={pageTheme}
            color={accentColor}
            webTvId={webTvId}
            playlistId={playlist?.id}
          />
        )
      });
    }

    if (shouldShowChapter) {
      tmpItems.push({
        key: 'chapters',
        label: (
          <span data-cy="tab-chapter">
            {screens.sm ? (
              t('chapters')
            ) : (
              <>
                <BookOutlined
                  style={{
                    fontSize: 16,
                    color:
                      pageTheme === ' dark' ? 'rgba(255, 255, 255, .85' : 'auto'
                  }}
                />
                {activeKey === 'chapters' && t('chapters')}
              </>
            )}
          </span>
        ),
        children: (
          <div className="tab__container">
            <Chapters
              mediaId={contentTypePlaying === 'media' ? contentIdPlaying : null}
              liveId={contentTypePlaying === 'live' ? contentIdPlaying : null}
              playlistId={playlist?.id}
              webTvId={webTvId}
              hasUniqueChapterFeed={playlist?.hasUniqueChapterFeed}
              allowClick={
                contentTypePlaying === 'media' ||
                (contentTypePlaying === 'live' && contentPlaying?.hasDVR)
              }
              color={accentColor}
            />
          </div>
        )
      });
    }

    // if (shouldShowTranscript) {
    //   tmpItems.push({
    //     key: 'transcript',
    //     label: (
    //       <span data-cy="tab-transcript">
    //         {screens.sm ? (
    //           t('transcript')
    //         ) : (
    //           <>
    //             <TranslationOutlined
    //               style={{
    //                 fontSize: 16,
    //                 color:
    //                   pageTheme === ' dark' ? 'rgba(255, 255, 255, .85' : 'auto'
    //               }}
    //             />
    //             {activeKey === 'transcript' && t('transcript')}
    //           </>
    //         )}
    //       </span>
    //     ),
    //     children: (
    //       <div className="tab__container">
    //         <Transcript subtitles={transcripts} color={accentColor} />
    //       </div>
    //     )
    //   });
    // }

    if (shouldShowFile) {
      tmpItems.push({
        key: 'files',
        label: (
          <span data-cy="tab-file">
            {screens.sm ? (
              t('files')
            ) : (
              <>
                <FileOutlined
                  style={{
                    fontSize: 16,
                    color:
                      pageTheme === ' dark' ? 'rgba(255, 255, 255, .85' : 'auto'
                  }}
                />
                {activeKey === 'files' && t('files')}
              </>
            )}
          </span>
        ),
        children: (
          <div className="tab__container">
            <Files files={files} />
          </div>
        )
      });
    }

    setItems(tmpItems);
  }, [
    screens,
    content,
    isPlaylist,
    isQAndA,
    shouldShowPoll,
    shouldShowDiscussion,
    shouldShowChapter,
    shouldShowFile,
    activeKey,
    activeQuestion,
    count
  ]);

  return (
    <div style={{ height: '100%', position: 'relative' }}>
      {/* {screens.sm && (
        <Button
          type="link"
          style={{
            fontSize: ' 1.6em',
            top: 0,
            left: 0,
            position: 'absolute',
            zIndex: 1000,
            width: 50,
            height: 45,
          }}
          data-cy="sidebar-trigger"
          onClick={() => setCollapsed(!collapsed)}
        >
           <HideSidebarIcon style={{
            fontSize: 20
           }}/>
        </Button>
      )} */}
      <Tabs
        activeKey={activeKey}
        onChange={onUpdateTabs}
        size={screens.sm ? 'middle' : 'small'}
        items={items}
      />

      <style jsx>{`
        .container {
          height: 100%;
        }
      `}</style>
      <style global jsx>{`
        .ant-tabs-tab > .ant-tabs-tab-btn {
          color: ${
            pageTheme === 'dark'
              ? 'rgba(255, 255, 255, .85) !important'
              : 'auto'
          } ;
        }
        .ant-layout-sider,.ant-layout-sider-light{
          
    max-width:  400px !important;
    min-width: 200px !important;
    width: 40% !important;

        }
        .ant-tabs-nav {
          padding: ${screens.sm ? 'initial' : '0 0 0 24px'};
          // padding-left: ${screens.sm ? '48px' : 'initial'};
          background: ${pageTheme === 'dark' ? '#252525' : '#f7f7f7'};
          margin: ${screens.sm ? '0px' : '8px 0 0'} !important;
          box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
        }

        .ant-tabs-nav-wrap,
        .ant-tabs-nav-list {
          width: 100%;
        }

        .ant-tabs-nav-list .ant-tabs-tab {
          margin: 0 !important;
        }

        .ant-tabs-nav-list .ant-tabs-tab:last-of-type {
          margin-right: 0 !important;
        }

        .ant-tabs-tab {
          display: flex;
          justify-content: center;
          flex-grow: 1;
          padding: ${screens.sm ? '16px 8px;' : '16px 4px'};
          margin: 0 12px;
        }

        .ant-tabs-tab + .ant-tabs-tab {
          margin: 0 16px;
        }

        .ant-tabs-content {
          height: ${screens.sm ? '100%' : tabHeight};
          overflow-x: ${screens.sm ? 'auto' : 'hidden'};
        }

        .ant-tabs,
        .ant-tabs-content-holder {
          height: 100%;
          max-height: 100%;
        }

        .ant-tabs-tabpane {
          height: 100%;
        }

        .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::before,
        .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
        .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::after,
        .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
        .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::before,
        .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
        .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::after,
        .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
          opacity: 0;
        }

        .tab-title {
          font-size 16px;
          margin: 0 0 16px;
        }

        // fIX FOR SAFARI
        .ant-tabs-content-holder {
          display: flex;
        }

        .ant-tabs-tab .ant-tabs-tab-btn .ant-badge {
          color: inherit;
        }
  
        .ant-tabs-tab .ant-tabs-tab-btn {
          font-weight: 500;
        }

        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn .ant-badge {
          color: ${accentColor} !important;
          
        }
        .ant-tabs-tab span {
          display: flex;
          align-items: center;
          line-height: 1;
        }
        .tab__container {
          min-height: 100%;
          padding: 16px;
        }
      `}</style>
    </div>
  );
};

export default memo(TabsPlayer);
