import Icon from '@ant-design/icons';

const Menu = (props) => (
  <svg
    version="1.1"
    viewBox="0 0 18.855469 15.095703"
    width="1em"
    height="1em"
    fill="currentColor"
  >
    <path
      transform="translate(-522.929 -5.129)"
      d="M523.93 5.129a1 1 0 0 0-1.001 1 1 1 0 0 0 1.002 1h16.853a1 1 0 0 0 1-1 1 1 0 0 0-1-1zm0 6.549a1 1 0 0 0-1.001 1 1 1 0 0 0 1.002 1h16.853a1 1 0 0 0 1-1 1 1 0 0 0-1-1zm-.001 6.547a1 1 0 0 0-1 1 1 1 0 0 0 1 1h16.857a1 1 0 0 0 .998-1 1 1 0 0 0-.998-1z"
    />
  </svg>
);

export const MenuIcon = (props) => <Icon component={Menu} {...props} />;

const ShowSidebar = () => (
  <svg
    version="1.1"
    viewBox="0 0 18.856 17.231"
    width="1em"
    height="1em"
    fill="currentColor"
  >
    <g transform="translate(-481.829 -45.494)">
      <path d="M499.452 45.494a1.23 1.23 0 01.871 2.102l-6.513 6.513 6.513 6.514a1.23 1.23 0 11-1.74 1.742l-7.373-7.37a1.25 1.25 0 010-1.768l7.373-7.372c.23-.23.543-.36.87-.36zm-9.017 0a1.23 1.23 0 01.87 2.102l-6.513 6.513 6.514 6.514a1.23 1.23 0 11-1.74 1.742l-7.371-7.37a1.25 1.25 0 010-1.768l7.37-7.372c.231-.23.544-.36.87-.36z"></path>
    </g>
  </svg>
);

export const ShowSidebarIcon = (props) => (
  <Icon component={ShowSidebar} {...props} />
);

const HideSidebar = () => (
  <svg
    version="1.1"
    viewBox="0 0 18.856 17.231"
    width="1em"
    height="1em"
    fill="currentColor"
  >
    <g transform="translate(-481.829 -5.494)">
      <path d="M483.061 5.494a1.23 1.23 0 00-.871 2.102l6.514 6.513-6.514 6.514a1.23 1.23 0 101.74 1.742l7.373-7.37a1.25 1.25 0 000-1.768l-7.373-7.372a1.23 1.23 0 00-.869-.36zm9.018 0a1.23 1.23 0 00-.872 2.102l6.514 6.513-6.514 6.514a1.23 1.23 0 101.74 1.742l7.372-7.37a1.25 1.25 0 000-1.768l-7.371-7.372a1.23 1.23 0 00-.87-.36z"></path>
    </g>
  </svg>
);

export const HideSidebarIcon = (props) => (
  <Icon component={HideSidebar} {...props} />
);
