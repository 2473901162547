import { Layout, Tooltip, Typography } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import React from 'react';
import { getFontSize, getMaxWidth } from '../../utils/functions/header';

import Style from './style';
import { GetBrandSettingFromRecoil } from '../../recoil/brandsetting';
import bgHeader from '../../public/SUAB_header2.png';
import Logo from '../../public/logo-Sorbonne-UE.png';
const { Header: AntHeader } = Layout;
const { Paragraph } = Typography;
const HeaderDropdown = dynamic(() => import('./HeaderDropdown'));

const Header = function ({ animatorPage }) {
  const { t } = useTranslation('common');
  const screens = useBreakpoint();
  const { accentColor, pageTitle, pageLogo, pageTheme } =
    GetBrandSettingFromRecoil();
  return (
    <AntHeader
      style={{
        padding: 0,
        // height: '200px',
        position: 'relative',
        width: '100%',
        margin: '0 auto',
        display: 'flex',
        alignItems: 'center'
      }}
      id="header"
    >
      <div
        className="titleLogoContainer"
        style={{
          position: 'absolute',
          color: '#fff !important',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: screens.xs ? '0 0px 0 25px' : '0 110px 0 25px',
          width: '100%',
          color: '#fff',
        }}
      >
        <div>
          <p>Webinaire</p>
          <h1
            style={{
              fontSize: screens.xs ? '4vw' : '2.5vw',
              fontWeight: 900,
              fontFamily: 'Raleway !important'
            }}
          >
            Sorbonne Université <br />
            Abou Dabi
          </h1>
          <h2
            style={{
              fontSize: screens.xs ? '3vw' : '2vw',
              fontFamily: 'Raleway !important'
            }}
          >
            Devenez les leaders de demain
          </h2>
        </div>
        <div>
          <img src={Logo.src} alt="logo" width={screens.xs ? '60%' : '100%'} />
        </div>
        {/* <div className="logoContainer">
            <div className="logoSubContainer">
              <div>
                {pageLogo && (
                  <img
                    src={pageLogo}
                    alt={`Logo ${pageTitle}`}
                    data-cy="logo"
                    style={{
                      maxWidth: '100%'
                    }}
                  />
                )}
              </div>
            </div>
          </div> */}
        {/* <Tooltip
            placement="bottom"
            title={pageTitle}
            trigger={['click', 'hover']}
          >
            <Paragraph
              style={{
                margin: 0,
                maxWidth: getMaxWidth(screens),
                fontSize: getFontSize(screens),
                fontWeight: 'bold'
              }}
              ellipsis={{
                rows: screens.xs ? 2 : 1,
                expandable: false
              }}
              data-cy="page-title"
              className="title"
            >
              {animatorPage && (
                <span className="animator">{t('animator-page')}</span>
              )}
              <h1
                style={{
                  fontSize: screens.xs ? 18 : 24,
                  margin: 0,
                  lineHeight: 1.2,
                  color:
                    pageTheme === 'dark' ? 'rgba(255,255,255,0.85)' : 'auto'
                }}
              >
                {pageTitle}
              </h1>
            </Paragraph>
          </Tooltip> */}
      </div>
      <div
        className="headerContainer"
        data-cy="header"
        style={{ width: '100%' }}
      >
        <img
          src={bgHeader.src}
          style={{
            objectFit: 'cover !important',
            objectPosition: 'center',
            width: '100%'
          }}
          alt="logo"
        />

        {/* <HeaderDropdown animatorPage={animatorPage} /> */}
      </div>
      {/* TODO: moove styles bellow in component */}
      {/* <Style screens={screens} /> */}
      <style>{`
        .mobileMenuContainer {
          display: flex;
          justify-content: space-between;
          padding: 16px;
        }
        .open-menu {
          padding-right: 12px;
        }
        .open-menu:hover {
          color: ${accentColor};
        }
      `}</style>
      <style jsx global>{`
        .ant-dropdown-menu-item {
          padding: ${screens.sm ? '8px 16px' : '12px 20px'};
          font-size: ${screens.sm ? '14px' : '16px'};
          background-color: ${pageTheme === 'dark'
            ? '#2f3237'
            : 'rgba(255,255,255,0.85)'};
          color: ${pageTheme === 'dark' ? 'rgba(255,255,255,0.85)' : '#000'};
        }
        .ant-dropdown-menu {
          background-color: ${pageTheme === 'dark'
            ? '#2f3237'
            : 'rgba(255,255,255,0.85)'} !important;
          color: ${pageTheme === 'dark' ? 'rgba(255,255,255,0.85)' : '#000'};
        }
        .ant-dropdown-menu-item-active {
          background-color: ${pageTheme === 'dark'
            ? '#1d1e20'
            : 'rgba(255,255,255,0.85)'} !important;
          color: ${pageTheme === 'dark' ? 'rgba(255,255,255,0.85)' : '#000'};
        }
        // .ant-dropdown-arrow {
        //   background: ${pageTheme === 'dark'
          ? '#2f3237'
          : 'rgba(255,255,255,0.85)'} !important;
        // }
        .ant-dropdown-arrow::before {
          background: ${pageTheme === 'dark'
            ? '#2f3237'
            : 'rgba(255,255,255,0.85)'} !important;
        }
      `}</style>
    </AntHeader>
  );
};

export default Header;
