export const getHeaderHeight = (screens) => {
  if (screens.sm) return 60;
  return 55;
};

export const getMaxWidth = (screens) => {
  if (screens.xs) {
    return '55vw';
  } else if (screens.sm && !screens.sm) {
    return '50vw';
  } else if (screens.sm && !screens.lg) {
    return '60vw';
  } else if (!screens.xl) {
    return ' 65vw';
  } else {
    return '70vw';
  }
};

export const getFontSize = (screens) => {
  if (screens.xs) {
    return '0.92rem';
  } else if (screens.sm && !screens.sm) {
    return '1.2rem';
  } else {
    return '1.5rem';
  }
};
